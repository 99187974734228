import React from 'react';

const GlowingWrapper = ({ children }) => {
  return (
    <div className="relative p-1 rounded-lg shadow-xl">
      <div className="absolute inset-0 bg-gradient-to-r from-yellow-500 to-purple-600 rounded-lg animate-glow"></div>
      <div className="relative bg-gray-800 p-8 rounded-lg">
        {children}
      </div>
      <style >{`
        @keyframes glow {
          0% {
            box-shadow: 0 0 10px rgba(255, 215, 0, 0.7), 0 0 20px rgba(255, 105, 180, 0.7), 0 0 30px rgba(138, 43, 226, 0.7), 0 0 40px rgba(255, 105, 180, 0.7);
          }
          50% {
            box-shadow: 0 0 20px rgba(255, 215, 0, 0.7), 0 0 30px rgba(255, 105, 180, 0.7), 0 0 40px rgba(138, 43, 226, 0.7), 0 0 50px rgba(255, 105, 180, 0.7);
          }
          100% {
            box-shadow: 0 0 10px rgba(255, 215, 0, 0.7), 0 0 20px rgba(255, 105, 180, 0.7), 0 0 30px rgba(138, 43, 226, 0.7), 0 0 40px rgba(255, 105, 180, 0.7);
          }
        }
        .animate-glow {
          animation: glow 2s infinite;
        }
      `}</style>
    </div>
  );
};

export default GlowingWrapper;
