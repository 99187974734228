import React, { useState, useEffect, useCallback } from 'react';

const questions = [
  {
    question: '¿Qué característica de Python se utiliza para definir bloques de código?',
    options: ['Llaves {}', 'Paréntesis ()', 'Indentación', 'Corchetes []'],
    answer: 'Indentación',
    feedback: 'Python utiliza la indentación para definir bloques de código, lo que mejora la legibilidad.',
    videoUrl: ''
  },
  {
    question: '¿Cuál es el resultado de print(type(5.0))?',
    options: ['<class \'int\'>', '<class \'float\'>', '<class \'number\'>', '<class \'double\'>'],
    answer: '<class \'float\'>',
    feedback: 'En Python, los números decimales son del tipo float.',
    videoUrl: ''
  },
  {
    question: '¿Cuáles de las siguientes son estructuras de control en Python?',
    options: ['if', 'for', 'while', 'try'],
    answer: ['if', 'for', 'while'],
    feedback: 'if, for, y while son estructuras de control en Python. try es para manejo de excepciones.',
    videoUrl: ''
  },
  {
    question: '¿Cómo se define una función en Python?',
    options: ['function myFunction():', 'def myFunction():', 'fun myFunction():', 'define myFunction():'],
    answer: 'def myFunction():',
    feedback: 'En Python, las funciones se definen usando la palabra clave "def".'
  },
  {
    question: '¿Cuál es el resultado de len("Python")?',
    options: ['5', '6', '7', 'Error'],
    answer: '6',
    feedback: 'La función len() devuelve la longitud de una secuencia. "Python" tiene 6 caracteres.'
  },
  {
    question: '¿Qué tipo de dato es el resultado de input()?',
    options: ['int', 'float', 'str', 'bool'],
    answer: 'str',
    feedback: 'La función input() siempre devuelve una cadena (str), incluso si el usuario ingresa un número.'
  },
  {
    question: '¿Cuál es la forma correcta de crear una lista en Python?',
    options: ['list = (1, 2, 3)', 'list = {1, 2, 3}', 'list = [1, 2, 3]', 'list = <1, 2, 3>'],
    answer: 'list = [1, 2, 3]',
    feedback: 'En Python, las listas se crean usando corchetes [].'
  },
  {
    question: '¿Qué hace el operador ** en Python?',
    options: ['Multiplicación', 'División', 'Potenciación', 'Módulo'],
    answer: 'Potenciación',
    feedback: 'El operador ** se usa para la potenciación en Python. Por ejemplo, 2**3 es 8.'
  },
  {
    question: '¿Cómo se maneja una excepción en Python?',
    options: ['using', 'try-catch', 'try-except', 'handle-error'],
    answer: 'try-except',
    feedback: 'En Python, las excepciones se manejan con bloques try-except.'
  },
  {
    question: '¿Cuál es el resultado de "Python"[1]?',
    options: ['P', 'y', 't', 'Error'],
    answer: 'y',
    feedback: 'En Python, el índice de las cadenas comienza en 0, por lo que "Python"[1] devuelve "y".'
  }
];

const PythonQuiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutos en segundos

  const handleOptionChange = (e, questionIndex) => {
    setSelectedOptions({ ...selectedOptions, [questionIndex]: e.target.value });
  };

  const handleSubmit = useCallback(() => {
    let newScore = 0;
    questions.forEach((question, index) => {
      if (Array.isArray(question.answer)) {
        if (question.answer.includes(selectedOptions[index])) {
          newScore++;
        }
      } else if (selectedOptions[index] === question.answer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);
  }, [selectedOptions]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      handleSubmit();
    }
  }, [timeLeft, handleSubmit]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white">
      <main className="flex-grow container mx-auto px-4 py-8">
        <section className="mb-16 text-center">
          <h2 className="text-4xl font-bold text-indigo-300 mb-6">Python Quiz</h2>
          <div className="text-lg text-gray-300 mb-6">
            No dudes en buscar información en Internet o usar recursos externos. ¡La idea es que aprendas!
          </div>
          {showResults ? (
            <div>
              <h3 className="text-3xl font-bold text-yellow-300">Resultados</h3>
              <p className="text-2xl mt-4">Tu puntaje es: {score} de {questions.length}</p>
              <div className="mt-8 space-y-4">
                {questions.map((question, index) => (
                  <div key={index} className="p-4 border rounded-lg bg-gray-800 shadow-lg">
                    <h4 className="text-xl font-bold text-yellow-200">{question.question}</h4>
                    <p className={`mt-2 ${Array.isArray(question.answer) ? (question.answer.includes(selectedOptions[index]) ? 'text-green-400' : 'text-red-400') : (selectedOptions[index] === question.answer ? 'text-green-400' : 'text-red-400')}`}>
                      {`Tu respuesta: ${selectedOptions[index] || "No respondida"}`}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-8">
                <h3 className="text-3xl font-bold text-cyan-400 mb-4">{questions[currentQuestion].question}</h3>
                <div className="mt-4 grid grid-cols-1 gap-2">
                  {questions[currentQuestion].options.map((option, index) => (
                    <label key={index} className="block text-left text-xl p-3 bg-gray-700 rounded-lg shadow hover:bg-gray-600 transition duration-200">
                      <input
                        type="radio"
                        name={`question-${currentQuestion}`}
                        value={option}
                        onChange={(e) => handleOptionChange(e, currentQuestion)}
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center mb-4">
                <button
                  onClick={() => setCurrentQuestion((prev) => prev - 1)}
                  disabled={currentQuestion === 0}
                  className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  Anterior
                </button>
                {currentQuestion < questions.length - 1 ? (
                  <button
                    onClick={() => setCurrentQuestion((prev) => prev + 1)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Siguiente
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                  >
                    Enviar
                  </button>
                )}
              </div>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default PythonQuiz;