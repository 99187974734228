import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaPython } from 'react-icons/fa';

const Academy = () => {
  const navigate = useNavigate();

  const handleQuizNavigation = (path) => {
    navigate(path);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <motion.main 
        className="container mx-auto px-4 py-16"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.section className="text-center mb-16" variants={itemVariants}>
          <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
            Fifty Academy
          </h1>
          <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-300">
            Bienvenido a Fifty Academy. Sumérgete en nuestros quizzes interactivos diseñados para potenciar tus habilidades en programación. 
            Desafía tus conocimientos, identifica tus fortalezas y áreas de mejora en diversas tecnologías. ¡Selecciona un quiz y comienza tu viaje de aprendizaje!
          </p>
        </motion.section>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
          variants={containerVariants}
        >
          <QuizButton
            icon={<FaPython />}
            title="Python"
            color="from-blue-500 to-blue-700"
            onClick={() => handleQuizNavigation('/academy/python/fundamentals')}
            variants={itemVariants}
          />
        </motion.div>
      </motion.main>
    </div>
  );
};

const QuizButton = ({ icon, title, color, onClick, variants }) => (
  <motion.button
    className={`bg-gradient-to-r ${color} text-white font-bold py-6 px-4 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out flex flex-col items-center justify-center space-y-4`}
    onClick={onClick}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    variants={variants}
  >
    <span className="text-4xl">{icon}</span>
    <span className="text-xl">{title}</span>
  </motion.button>
);

export default Academy;
