import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import HomePage from './components/home/HomePage';
import Academy from './components/academy/Academy';
import PythonQuiz from './components/academy/quizzes/PythonQuiz';
import SSLDocumentation from './documents/SSLDocumentation';
import FiftyInteractiveUniverse from './components/home/FiftyUniverse';
import LearningPathModule from './components/academy/PythonLearningPath';
import PythonFundamentals from './components/academy/paths/ForPython/PythonFundamentals';
import EsportsInfo from './components/verticals/EsportsInfo';
import RecordsInfo from './components/verticals/RecordsInfo';
import NewsInfo from './components/verticals/NewsInfo';
import NNdNandoPage from './components/FiftyRecords/artists/nNd_Nando/nNd_Nando';
/*import FiftyRoyalCurtainPage from './components/curtains/FiftyRoyalCurtainPage';*/
import FeedbackButton from './components/common/FeedbackButton';
import Dashboard from './components/ClashRoyale/Dashboard';
import FormClanContact from './components/ClashRoyale/fromClanContact';

// Componente que incluye Header y Footer
const LayoutWithHeaderFooter = ({ children }) => (
  <div className="min-h-screen bg-gray-800">
    <Header />
    {children}
    <FeedbackButton />
    <Footer />
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rutas con Header y Footer */}
        <Route path="/" element={<LayoutWithHeaderFooter><HomePage /></LayoutWithHeaderFooter>} />
        <Route path="/fifty-academy" element={<LayoutWithHeaderFooter><Academy /></LayoutWithHeaderFooter>} />
        <Route path="/quiz/python" element={<LayoutWithHeaderFooter><PythonQuiz /></LayoutWithHeaderFooter>} />
        <Route path="/documents/SSL" element={<LayoutWithHeaderFooter><SSLDocumentation /></LayoutWithHeaderFooter>} />
        <Route path="/fiftyuniverse" element={<LayoutWithHeaderFooter><FiftyInteractiveUniverse /></LayoutWithHeaderFooter>} />
        <Route path="/python-learning-path" element={<LayoutWithHeaderFooter><LearningPathModule /></LayoutWithHeaderFooter>} />
        <Route path="/academy/python/fundamentals" element={<LayoutWithHeaderFooter><PythonFundamentals /></LayoutWithHeaderFooter>} />
        <Route path="/esports-info" element={<LayoutWithHeaderFooter><EsportsInfo /></LayoutWithHeaderFooter>} />
        <Route path="/records-info" element={<LayoutWithHeaderFooter><RecordsInfo /></LayoutWithHeaderFooter>} />
        <Route path="/news-info" element={<LayoutWithHeaderFooter><NewsInfo /></LayoutWithHeaderFooter>} />
        <Route path="/Nando" element={<LayoutWithHeaderFooter><NNdNandoPage /></LayoutWithHeaderFooter>} />
        <Route path="/ClashRoyale/Dashboard" element={<LayoutWithHeaderFooter><Dashboard /></LayoutWithHeaderFooter>} /> 
        <Route path="/contact-clans" element={<LayoutWithHeaderFooter><FormClanContact /></LayoutWithHeaderFooter>} /> 

        {/* Ruta sin Header y Footer */}
        {/* <Route path="/cortinas" element={<FiftyRoyalCurtainPage />} /> */}

        {/* Ruta de captura para redirigir a la HomePage en caso de error */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
