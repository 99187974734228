import React, { useState, useMemo } from 'react';
import ClanInfo from './ClanInfo';
import RoleDistributionChart from './RoleDistributionChart';
import TrophyDistributionChart from './TrophyDistributionChart';
import MembersList from './MembersList';
import Podium from './Podium';
import clanInfo from './clanInfo.json';  // Importa el JSON generado
import { COLORS } from './constants';
import { roleHierarchy } from './config';

const Dashboard = () => {
  const [sortCriteria, setSortCriteria] = useState('trophies');
  const [filterPlayer, setFilterPlayer] = useState('');

  const sortedMembers = useMemo(() => {
    return [...clanInfo.memberList]
      .sort((a, b) => {
        if (sortCriteria === 'role') {
          return roleHierarchy.indexOf(a.role) - roleHierarchy.indexOf(b.role);
        }
        return b[sortCriteria] - a[sortCriteria];
      })
      .filter(member => member.name.toLowerCase().includes(filterPlayer.toLowerCase()));
  }, [sortCriteria, filterPlayer]);

  const roleDistribution = useMemo(() => {
    const distribution = clanInfo.memberList.reduce((acc, member) => {
      acc[member.role] = (acc[member.role] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(distribution).map(([role, count]) => ({ name: role, value: count }));
  }, []);

  return (
    <div className="p-6 bg-gray-900 text-white">
      <h1 className="text-4xl font-bold mb-4 text-purple-400">Fifty Royal</h1>
      <p className="text-lg mb-6 text-gray-300">Ampliando horizontes, transformando futuros</p>
      
      <ClanInfo clanInfo={clanInfo} />
      <Podium members={clanInfo.memberList} />
      <RoleDistributionChart data={roleDistribution} colors={COLORS} />
      <TrophyDistributionChart data={sortedMembers} colors={COLORS} />
      <MembersList
        members={sortedMembers}
        sortCriteria={sortCriteria}
        setSortCriteria={setSortCriteria}
        filterPlayer={filterPlayer}
        setFilterPlayer={setFilterPlayer}
      />
    </div>
  );
};

export default Dashboard;
