import React from 'react';
import { formatLastSeen } from './formatLastSeen';
import ClanActivityStatusBar from './ClanActivityStatusBar';
import clanInfo from './clanInfo.json';  // Importa el JSON que contiene toda la información del clan

const Member = ({ member }) => (
  <div className="bg-gray-700 shadow rounded-lg p-4">
    <h3 className="font-bold text-lg mb-2 text-purple-400">{member.name}</h3>
    <p><strong className="text-blue-400">Rol:</strong> {member.role}</p>
    <p><strong className="text-blue-400">Nivel:</strong> {member.expLevel}</p>
    <p><strong className="text-blue-400">Trofeos:</strong> {member.trophies}</p>
    <p><strong className="text-blue-400">Donaciones:</strong> {member.donations}</p>
    <p><strong className="text-blue-400">Última Conexión:</strong> {formatLastSeen(member.lastSeen)}</p>
  </div>
);

const MembersList = () => (
  <div className="bg-gray-800 shadow-lg rounded-lg p-6">
    <ClanActivityStatusBar members={clanInfo.memberList} />
    <h2 className="text-2xl font-semibold mb-4 text-pink-400">Miembros del Clan</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {clanInfo.memberList.map(member => (
        <Member key={member.tag} member={member} />
      ))}
    </div>
  </div>
);

export default MembersList;
