import React from 'react';
import GlowingWrapper from '../common/GlowingWrapper';

const MissionVision = () => {
  return (
    <section className="mb-16 mx-10">  {/* mx-5 agrega un margen horizontal de 1.25rem (20px) a ambos lados */}
      <GlowingWrapper>
        <div className="space-y-6">
          <div>
            <h3 className="text-2xl font-bold mb-3 text-yellow-400">Misión</h3>
            <p className="text-gray-300 text-lg">
              Nuestro compromiso es acompañar, inspirar y ofrecer oportunidades significativas a la comunidad para su participación activa y desarrollo continuo.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-3 text-purple-400">Visión</h3>
            <p className="text-gray-300 text-lg">
              Inspirar y capacitar a las nuevas generaciones a través de la innovación y el liderazgo, transformándolas en agentes de cambio que impacten positivamente en sus comunidades y el entorno global.
            </p>
          </div>
        </div>
      </GlowingWrapper>
    </section>
  );
};

export default MissionVision;
