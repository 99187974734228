import React from 'react';
import { Button } from './Button';
import { BookOpen, Lock, Unlock } from 'lucide-react';

const ProgressBarQuizButton = ({ 
  readTopicsCount, 
  totalTopics, 
  allTopicsRead, 
  startQuiz,
  moduleTitle,
  quizButtonText = 'Iniciar Quiz',
  quizLockedText = 'Quiz Bloqueado',
  progressBarColor = 'bg-blue-600'
}) => {
  return (
    <div className="sticky top-0 z-10 bg-white shadow-md p-4 mb-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
          <BookOpen className="mr-2 text-blue-600" />
          {moduleTitle}
        </h2>
        <div className="flex items-center">
          <div className="mr-4">
            <span className="font-bold">{readTopicsCount}</span> de <span className="font-bold">{totalTopics}</span> temas leídos
          </div>
          <Button
            onClick={startQuiz}
            disabled={!allTopicsRead}
            className={`${allTopicsRead ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gray-400'} text-white font-bold py-2 px-4 rounded-full transition-all duration-300 ease-in-out flex items-center`}
          >
            {allTopicsRead ? <Unlock className="mr-2" /> : <Lock className="mr-2" />}
            {allTopicsRead ? quizButtonText : quizLockedText}
          </Button>
        </div>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
        <div className={`${progressBarColor} h-2.5 rounded-full`} style={{ width: `${(readTopicsCount / totalTopics) * 100}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBarQuizButton;