import React, { useState } from 'react';
import { Button } from '../common/Button';  // Asegúrate de que la ruta de importación sea correcta
import { Crown, Cpu, Zap, Gamepad } from 'lucide-react';

const verticals = [
  { id: 'esports', name: 'Esports', color: '#FF6B6B', icon: '🏆', description: 'Transformando el futuro a través del juego competitivo.', link: '/esports-info', showMoreInfo: true },
  { id: 'labs', name: 'Labs', color: '#4ECDC4', icon: '🔬', description: 'Innovando y desarrollando nuevas tecnologías para la industria.', link: '/fifty-academy', showMoreInfo: true },
  { id: 'news', name: 'Noticias', color: '#FFA500', icon: '📰', description: 'Manteniendo a la comunidad informada con las últimas actualizaciones.', link: '/news-info', showMoreInfo: true },
  { id: 'community', name: 'Comunidad', color: '#45B7D1', icon: '🤝', description: 'Construyendo y nutriendo una red global de jugadores y entusiastas.', link: '/community-info', showMoreInfo: false },
  { id: 'records', name: 'Records', color: '#9B59B6', icon: '🎵', description: 'Promoviendo el talento musical de la comunidad.', link: '/records-info', showMoreInfo: true }
];

const PlanetButton = ({ vertical, isActive, onClick }) => (
  <div
    className={`w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full flex items-center justify-center cursor-pointer transition-all duration-300 m-2
                ${isActive ? `ring-4 ring-white scale-125 bg-[${vertical.color}] shadow-xl` : 'hover:scale-110 hover:ring-2 hover:ring-white/50 bg-gray-700'}`}
    onClick={() => onClick(vertical.id)}
  >
    <span className="text-2xl md:text-3xl lg:text-4xl">{vertical.icon}</span>
  </div>
);

const FiftyRoyalLogo = () => (
  <div className="relative w-full max-w-2xl aspect-video bg-gradient-to-b from-gray-900 rounded-lg shadow-2xl overflow-hidden mb-8">
    <style>{`
      @keyframes float {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-10px); }
      }
      .animate-float {
        animation: float 3s ease-in-out infinite;
      }
    `}</style>
    <div className="absolute inset-0 opacity-5">
      {[...Array(20)].map((_, i) => (
        <React.Fragment key={i}>
          <Cpu className="absolute text-cyan-400" style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            transform: 'scale(0.5)'
          }} />
          <Zap className="absolute text-cyan-400" style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            transform: 'scale(0.5)'
          }} />
          <Gamepad className="absolute text-cyan-400" style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            transform: 'scale(0.5)'
          }} />
        </React.Fragment>
      ))}
    </div>
    
    <div className="relative z-5 flex flex-col items-center justify-center h-full text-center">
      <div className="animate-float">
        <Crown className="w-16 h-16 text-yellow-400 mb-4" />
      </div>
      
      <h1 className="text-5xl font-bold text-yellow-400 mb-2 tracking-wider">
        FIFTY ROYAL
      </h1>
      
      <p className="text-sm font-medium mb-4">
        <span className="text-cyan-400">EXPANDING HORIZONS,</span>
        <br />
        <span className="text-white">TRANSFORMING FUTURES</span>
      </p>
    </div>
  </div>
);

const FiftyUniverse = () => {
  const [activeVertical, setActiveVertical] = useState(verticals[0].id);

  const handleInteraction = (id) => {
    setActiveVertical(id);
  };

  const currentVertical = verticals.find(v => v.id === activeVertical);

  return (
    <div className="bg-gray-900 flex flex-col items-center justify-start p-4">
      <FiftyRoyalLogo />
      <div className="flex flex-wrap justify-center mb-16 space-x-4">
        {verticals.map((vertical) => (
          <PlanetButton
            key={vertical.id}
            vertical={vertical}
            isActive={vertical.id === activeVertical}
            onClick={handleInteraction}
          />
        ))}
      </div>
      <div className="bg-gray-800 bg-opacity-80 backdrop-blur-md p-8 rounded-lg text-white max-w-2xl w-full shadow-2xl transition-transform transform hover:scale-105 hover:shadow-lg mb-16">
        <h2 className="text-2xl font-bold mb-4" style={{ color: currentVertical.color }}>{currentVertical.name}</h2>
        <p className="text-lg">{currentVertical.description}</p>
        {currentVertical.showMoreInfo && (
          <Button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => window.location.href = currentVertical.link}>
            Más Información
          </Button>
        )}
      </div>
    </div>
  );
};

export default FiftyUniverse;