import React, { useMemo } from 'react';

const ClanActivityStatusBar = ({ members }) => {
  const activityStatus = useMemo(() => {
    const now = new Date();
    const twoDaysAgo = new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000);
    const fiveDaysAgo = new Date(now.getTime() - 5 * 24 * 60 * 60 * 1000);

    return members.reduce((acc, member) => {
      // Asumiendo que el formato es "YYYYMMDDTHHMMSS.000Z"
      const lastSeenDate = new Date(member.lastSeen.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})\.000Z/, '$1-$2-$3T$4:$5:$6.000Z'));
      
      if (lastSeenDate > twoDaysAgo) {
        acc.active++;
      } else if (lastSeenDate > fiveDaysAgo) {
        acc.semiActive++;
      } else {
        acc.inactive++;
      }
      return acc;
    }, { active: 0, semiActive: 0, inactive: 0 });
  }, [members]);


  const total = members.length;
  const activePercentage = (activityStatus.active / total) * 100;
  const semiActivePercentage = (activityStatus.semiActive / total) * 100;
  const inactivePercentage = (activityStatus.inactive / total) * 100;

  return (
    <div className="bg-gray-800 shadow-lg rounded-lg p-6 mb-6">
      <h2 className="text-2xl font-semibold mb-4 text-pink-400">Estado de Actividad del Clan</h2>
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
              Activos: {activityStatus.active}
            </span>
          </div>
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200">
              Semi-activos: {activityStatus.semiActive}
            </span>
          </div>
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200">
              Inactivos: {activityStatus.inactive}
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-300">
          <div style={{ width: `${activePercentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
          <div style={{ width: `${semiActivePercentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"></div>
          <div style={{ width: `${inactivePercentage}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
        </div>
      </div>
      <div className="text-sm text-gray-400 mt-2">
        <p>Activos: Conectados en los últimos 2 días</p>
        <p>Semi-activos: Conectados entre 2 y 5 días atrás</p>
        <p>Inactivos: No se han conectado en más de 5 días</p>
      </div>
    </div>
  );
};

export default ClanActivityStatusBar;