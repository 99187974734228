import React from 'react';
import MissionVision from './MissionVision';
import InitiativesSection from './InitiativesSection'; // Importar el nuevo componente
import FiftyInteractiveUniverse from './FiftyUniverse';
import Timeline from '../common/Timeline';

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white">
      <main className="flex-grow container mx-auto">
        <FiftyInteractiveUniverse />
        <MissionVision />
        <InitiativesSection /> {/* Añadir el nuevo componente aquí */}
        <Timeline />
      </main>
    </div>
  );
};

export default HomePage;
