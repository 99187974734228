import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent } from '../common/Card';
import { Button } from '../common/Button';
import { ChevronRight, ChevronDown, BookOpen } from 'lucide-react';

const pythonLearningPath = [
  {
    id: 'fundamentos',
    title: "Fundamentos de Python",
    topics: [
      "Sintaxis básica",
      "Variables y tipos de datos",
      "Estructuras de control (if, for, while)",
      "Funciones",
      "Manejo de errores y excepciones"
    ]
  },
  {
    id: 'estructuras-datos',
    title: "Estructuras de Datos en Python",
    topics: [
      "Listas y tuplas",
      "Diccionarios",
      "Conjuntos",
      "Comprensiones de listas",
      "Trabajando con archivos"
    ]
  },
  {
    id: 'poo',
    title: "Programación Orientada a Objetos",
    topics: [
      "Clases y objetos",
      "Herencia",
      "Polimorfismo",
      "Encapsulamiento",
      "Métodos especiales"
    ]
  },
  {
    id: 'modulos-paquetes',
    title: "Módulos y Paquetes",
    topics: [
      "Importación de módulos",
      "Creación de módulos propios",
      "Paquetes populares (NumPy, Pandas)",
      "Entornos virtuales",
      "Gestor de paquetes pip"
    ]
  },
  {
    id: 'proyectos',
    title: "Proyectos Prácticos",
    topics: [
      "Análisis de datos simple",
      "Creación de una API REST",
      "Automatización de tareas",
      "Juego simple con Pygame",
      "Web scraping básico"
    ]
  }
];

const LearningPathModule = () => {
    const [expandedSections, setExpandedSections] = useState({});
    const navigate = useNavigate();
  
    const toggleSection = (index, event) => {
      if (!event.target.closest('button')) {
        setExpandedSections(prev => ({
          ...prev,
          [index]: !prev[index]
        }));
      }
    };
  
    const handleStartSection = (sectionId, event) => {
      event.stopPropagation();
      if (sectionId === 'fundamentos') {
        navigate('/academy/python/fundamentals');
      } else {
        console.log(`Iniciando sección: ${sectionId}`);
      }
    };
  
    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800 flex items-center justify-center">
          <BookOpen className="mr-2 text-blue-600" />
          Recorrido de Aprendizaje: Python
        </h2>
        <p className="text-gray-600 mb-6 text-center">
          Explora nuestro programa estructurado para dominar Python. Elige una sección para comenzar tu viaje de aprendizaje.
        </p>
        {pythonLearningPath.map((section, index) => (
          <Card key={index} className="mb-4 border border-gray-200">
            <CardHeader 
              className="flex justify-between items-center cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
              onClick={(e) => toggleSection(index, e)}
            >
              <div className="flex items-center justify-between w-full">
                <h3 className="text-lg font-medium text-gray-800">{section.title}</h3>
                <div className="flex items-center">
                  <Button 
                    className="mr-2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-1 px-3 rounded text-sm transition-colors duration-200"
                    onClick={(e) => handleStartSection(section.id, e)}
                  >
                    Iniciar
                  </Button>
                  {expandedSections[index] ? 
                    <ChevronDown className="text-gray-600" /> : 
                    <ChevronRight className="text-gray-600" />
                  }
                </div>
              </div>
            </CardHeader>
            <CardContent 
              className={`bg-white transition-all duration-200 ease-in-out ${
                expandedSections[index] ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
              } overflow-hidden`}
            >
              <ul className="list-disc pl-5 text-gray-700">
                {section.topics.map((topic, topicIndex) => (
                  <li key={topicIndex} className="mb-1">
                    {topic}
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };
  
  export default LearningPathModule;