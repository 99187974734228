import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from '../../../common/Card';
import { Button } from '../../../common/Button';
import { Code, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ProgressBarQuizButton from '../../../common/ProgressBarQuizButton';

const fundamentalsTopics = [
  {
    title: "Sintaxis básica",
    content: "Python utiliza indentación para definir bloques de código. Esto hace que el código sea más legible y consistente. Aprenderás sobre comentarios, declaraciones multilinea, y la estructura básica de un programa Python.",
    example: `
# Esto es un comentario
print("Hola, mundo!")  # Esto imprime un mensaje

if True:
    print("Este bloque está indentado")
    print("La indentación define el bloque")

"""
Esto es un comentario
de múltiples líneas
"""
`
  },
  {
    title: "Variables y tipos de datos",
    content: "Exploraremos los tipos de datos básicos en Python como enteros, flotantes, cadenas y booleanos. Aprenderás cómo declarar variables, asignarles valores y las convenciones de nombrado en Python.",
    example: `
# Entero
edad = 25

# Flotante
altura = 1.75

# Cadena
nombre = "Alice"

# Booleano
es_estudiante = True

# Imprimiendo variables
print(f"{nombre} tiene {edad} años y mide {altura} metros.")
print(f"¿Es estudiante? {es_estudiante}")
`
  },
  {
    title: "Estructuras de control (if, for, while)",
    content: "Aprenderás a controlar el flujo de tu programa con estructuras condicionales (if, elif, else) y bucles (for, while). Esto te permitirá crear programas más complejos y tomar decisiones basadas en condiciones.",
    example: `
# Estructura if-elif-else
edad = 18
if edad < 18:
    print("Eres menor de edad")
elif edad == 18:
    print("Acabas de alcanzar la mayoría de edad")
else:
    print("Eres mayor de edad")

# Bucle for
for i in range(5):
    print(f"Iteración {i}")

# Bucle while
contador = 0
while contador < 3:
    print(f"Contador: {contador}")
    contador += 1
`
  },
  {
    title: "Funciones",
    content: "Las funciones son bloques de código reutilizables. Aprenderás a definir funciones, pasar argumentos, utilizar valores de retorno y entender el concepto de alcance de variables.",
    example: `
def saludar(nombre):
    return f"Hola, {nombre}!"

def suma(a, b):
    return a + b

# Llamando a las funciones
mensaje = saludar("María")
print(mensaje)

resultado = suma(5, 3)
print(f"5 + 3 = {resultado}")

# Función con argumento por defecto
def potencia(base, exponente=2):
    return base ** exponente

print(potencia(3))     # 3^2 = 9
print(potencia(3, 3))  # 3^3 = 27
`
  },
  {
    title: "Manejo de errores y excepciones",
    content: "Python utiliza excepciones para manejar errores. Aprenderás a usar bloques try-except para capturar y manejar errores, permitiendo que tus programas sean más robustos y resistentes a fallos.",
    example: `
try:
    numero = int(input("Introduce un número: "))
    resultado = 10 / numero
    print(f"10 dividido por {numero} es {resultado}")
except ValueError:
    print("Error: Debes introducir un número válido.")
except ZeroDivisionError:
    print("Error: No se puede dividir por cero.")
except Exception as e:
    print(f"Ocurrió un error inesperado: {e}")
else:
    print("La operación se realizó con éxito.")
finally:
    print("Este bloque siempre se ejecuta, haya error o no.")
`
  }
];

const PythonFundamentals = () => {
    const [readTopics, setReadTopics] = useState({});
    const navigate = useNavigate();
  
    const markAsRead = (index) => {
      setReadTopics(prev => ({...prev, [index]: true}));
    };
  
    const allTopicsRead = Object.keys(readTopics).length === fundamentalsTopics.length;
  
    const startQuiz = () => {
      navigate('/quiz/python');
    };
  
    const totalTopics = fundamentalsTopics.length;
    const readTopicsCount = Object.keys(readTopics).length;
  
    return (
      <div className="relative">
        <ProgressBarQuizButton 
          readTopicsCount={readTopicsCount}
          totalTopics={totalTopics}
          allTopicsRead={allTopicsRead}
          startQuiz={startQuiz}
        />
  
        <div className="max-w-4xl mx-auto p-6">
          {fundamentalsTopics.map((topic, index) => (
            <Card key={index} className="mb-8 border border-gray-200">
              <CardHeader className="bg-gray-50 flex justify-between items-center">
                <h3 className="text-xl font-medium text-gray-800">{topic.title}</h3>
                {readTopics[index] && <CheckCircle className="text-green-500" />}
              </CardHeader>
              <CardContent>
                <p className="text-white mb-4">{topic.content}</p>
                <div className="bg-gray-100 p-4 rounded-md">
                  <h4 className="text-lg font-medium text-gray-800 mb-2 flex items-center">
                    <Code className="mr-2 text-blue-600" />
                    Ejemplo
                  </h4>
                  <pre className="bg-gray-800 text-white p-4 rounded-md overflow-x-auto">
                    <code>{topic.example}</code>
                  </pre>
                </div>
                <div className="mt-4">
                  <Button
                    onClick={() => markAsRead(index)}
                    disabled={readTopics[index]}
                    className={`w-full ${readTopics[index] ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded transition-colors duration-300`}
                  >
                    {readTopics[index] ? 'Leído' : 'Marcar como leído'}
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  };
  
  export default PythonFundamentals;