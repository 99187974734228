import React from 'react';

const InitiativesSection = () => {
  return (
    <section className="mb-16 mx-10"> {/* Aquí se agrega un margen horizontal de 1.25rem (20px) a ambos lados */}
      <div className="bg-gray-800 p-8 rounded-lg shadow-xl space-y-8">
        <div>
          <div className="flex items-center mb-4">
          <svg
  className="w-12 h-12 text-yellow-400 mr-4"
  fill="none"
  stroke="currentColor"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="xMidYMid meet"
>
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M12 2c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM12 10c-2.21 0-4 1.79-4 4v5h8v-5c0-2.21-1.79-4-4-4zm-6 6h2v2H6v-2zm8 0h2v2h-2v-2zm-8 4h12v2H6v-2z"
  ></path>
</svg>
            <h3 className="text-2xl font-bold text-yellow-400">Apoyamos Iniciativas</h3>
          </div>
          <p className="text-gray-300 text-lg">
            En Fifty apoyamos las iniciativas de nuestros miembros, hablamos de las últimas tendencias en esports y tecnología, y realizamos proyectos innovadores en el ámbito de los videojuegos y el gaming competitivo.
          </p>
        </div>
        
        <div>
          <div className="flex items-center mb-4">
            <svg
              className="w-12 h-12 text-purple-400 mr-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 10h18M3 6h18M3 14h18M3 18h18"
              ></path>
            </svg>
            <h3 className="text-2xl font-bold text-purple-400">Aprendizaje</h3>
          </div>
          <p className="text-gray-300 text-lg">
            Únete a nuestro grupo para colaborar con otros entusiastas, aprender nuevas habilidades y contribuir a proyectos que pueden tener un impacto significativo en el mundo de los esports.
          </p>
        </div>
        
        <div>
          <div className="flex items-center mb-4">
            <svg
              className="w-12 h-12 text-green-400 mr-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M20 12c0 4.418-4.03 8-9 8s-9-3.582-9-8m18 0c0-4.418-4.03-8-9-8s-9 3.582-9 8m18 0h-6m-6 0h-6"
              ></path>
            </svg>
            <h3 className="text-2xl font-bold text-green-400">Entorno Colaborativo</h3>
          </div>
          <p className="text-gray-300 text-lg">
            Estamos comprometidos con la creación de un entorno colaborativo y de apoyo donde cada miembro tenga la oportunidad de crecer y desarrollarse profesionalmente.
          </p>
        </div>
      </div>
    </section>
  );
};

export default InitiativesSection;
