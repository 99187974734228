import React from 'react';
import { FaDiscord } from 'react-icons/fa';

const Header = () => {
  return (
    <header>
      <nav className="bg-gray-800 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <div className="h-20 w-20 flex items-center justify-center">
              <img
                src="/images/logo192.png"
                alt="Fifty Royal"
                className="h-13 rounded-full"
                style={{ transition: 'transform 1s', transformOrigin: 'center' }} // Asegúrate de que la rotación es alrededor del centro
              />
            </div>
          </div>
          <div className="flex items-center space-x-6">
            <a href="/" className="text-white hover:text-purple-400 transition duration-300">Inicio</a>
            <button
              onClick={() => window.open('https://discord.gg/ZkyZbAbSDr', '_blank')}
              className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center"
            >
              <FaDiscord className="h-6 w-6 mr-2" />Únete ahora
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
